import { generatePath } from 'react-router-dom';

export const POSITION_ROUTES = {
  positionOwners: '/positions/owners/list',
  positionOwnersSuggestion: '/positions/owners/suggestion',
  positionOwnerExists: '/positions/owners/exists',
  positionOwnersCombo: '/positions/owners',
  createPositionOwner: '/positions/owners',
  getPositionOwner: {
    route: '/positions/owners/:id',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
  positionStatus: '/positions/status/list',
  positionStatusSuggestion: '/positions/status/suggestion',
  positionStatusExists: '/positions/status/exists',
  createPositionStatus: '/positions/status',
  positionStatusDefaults: '/positions/status/defaults',
  getPositionStatus: {
    route: '/positions/status/:id',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
  positionsFiltersSearch: '/positions/filters/search',
  positionsFiltersOptions: '/positions/filters/options',
  positionsFilters: '/positions/filters',
  positionsBands: '/positions/bands',
  positionsOwnersStructures: '/positions/owners/structures',
  postPositions: '/positions',
  getPositionsExistsWorkdayIdPositionId: '/positions/exists',
  positionsOverviewFiltersSearch: '/positions/overview/filters/search',
  positionsOverviewFiltersOptions: '/positions/overview/filters/options',
  positionsOverviewFilters: '/positions/overview/filters',
  getEmployeesByPositionId: {
    route: '/positions/:id/employees',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
  getPositions: '/positions',
  getPositionById: {
    route: '/positions/:id',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
  updatePosition: {
    route: '/positions/:id',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
  getPositionByIdHasPeople: {
    route: '/positions/:id/hasPeople',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
  getPositionStatusForEdit: '/positions/status/forEditFlow',
  getPositionsUserHasAssignment: '/positions/owners/userHasAssignment',
};
