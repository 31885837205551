export const VALUE_STREAM_MANAGEMENT_FILTER_GUARDIANS = 'value-stream-management-filter-guardians';
export const SCREEN_POSITION_CONFIGURATION = 'screen-position-configuration';
export const SCREEN_POSITION_MANAGEMENT = 'screen-position-management';

export const EMPLOYEE_JOB_FAMILY_CAREER = 'employee-job-family-career';

export const POSITION_ADD_PERSON = 'position-add-person';
export const POSITION_EDIT_PERSON = 'position-edit-person';
export const POSITION_ID_VIEW = 'position-inactivate-person';
export const POSITION_HISTORY_LOGS = 'position-history-logs';
