import {
  AddTag,
  AlertOctagon,
  Bookmark,
  Building,
  Calendar,
  CategoryAdd,
  CategorySearch,
  ChartTree,
  Check2,
  Checkmark2,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clock,
  Connect,
  Copy,
  Download,
  Edit2,
  Grid,
  HelpCircle,
  Home,
  Info,
  Menu,
  MoreHorizontal,
  Package,
  Plus,
  Receipt,
  Search,
  Settings,
  Sliders,
  Social,
  Trash2,
  TreeDiagram,
  Unlock,
  Upload,
  User,
  Users,
  UserSettings,
  X,
  XCircle,
} from '@hexa-ui/icons';

export const AddTagIcon = ({ ...props }) => <AddTag {...props} />;
export const BuildingIcon = ({ ...props }) => <Building {...props} />;
export const TreeDiagramIcon = ({ ...props }) => <TreeDiagram {...props} />;
export const PackageIcon = ({ ...props }) => <Package {...props} />;
export const ChartTreeIcon = ({ ...props }) => <ChartTree {...props} />;
export const GridIcon = ({ ...props }) => <Grid {...props} />;
export const SocialIcon = ({ ...props }) => <Social {...props} />;
export const ConnectIcon = ({ ...props }) => <Connect {...props} />;
export const UsersIcon = ({ ...props }) => <Users {...props} />;
export const HelpCircleIcon = ({ ...props }) => <HelpCircle {...props} />;
export const DownloadIcon = ({ ...props }) => <Download {...props} />;
export const XIcon = ({ ...props }) => <X {...props} />;
export const PlusIcon = ({ ...props }) => <Plus {...props} />;
export const SettingsIcon = ({ ...props }) => <Settings {...props} />;
export const SearchIcon = ({ ...props }) => <Search {...props} />;
export const EditIcon = ({ ...props }) => <Edit2 {...props} />;
export const TrashIcon = ({ ...props }) => <Trash2 {...props} />;
export const InfoIcon = ({ ...props }) => <Info {...props} />;
export const AlertOctagonIcon = ({ ...props }) => <AlertOctagon {...props} />;
export const HomeIcon = ({ ...props }) => <Home {...props} />;
export const CategoryAddIcon = ({ ...props }) => <CategoryAdd {...props} />;
export const CategorySearchIcon = ({ ...props }) => <CategorySearch {...props} />;
export const ClockIcon = ({ ...props }) => <Clock {...props} />;
export const ChevronLeftIcon = ({ ...props }) => <ChevronLeft {...props} />;
export const ChevronRightIcon = ({ ...props }) => <ChevronRight {...props} />;
export const ChevronDownIcon = ({ ...props }) => <ChevronDown {...props} />;
export const ChevronUpIcon = ({ ...props }) => <ChevronUp {...props} />;
export const CheckIcon = ({ ...props }) => <Check2 {...props} />;
export const CheckMarkIcon = ({ ...props }) => <Checkmark2 {...props} />;
export const XCircleIcon = ({ ...props }) => <XCircle {...props} />;
export const SlidersIcon = ({ ...props }) => <Sliders {...props} />;
export const ReceiptIcon = ({ ...props }) => <Receipt {...props} />;
export const MenuIcon = ({ ...props }) => <Menu {...props} />;
export const UploadIcon = ({ ...props }) => <Upload {...props} />;
export const CalendarIcon = ({ ...props }) => <Calendar {...props} />;
export const CopyIcon = ({ ...props }) => <Copy {...props} />;
export const BookmarkIcon = ({ ...props }) => <Bookmark {...props} />;
export const MoreHorizontalIcon = ({ ...props }) => <MoreHorizontal {...props} />;
export const UnlockIcon = ({ ...props }) => <Unlock {...props} />;
export const UserSettingsIcon = ({ ...props }) => <UserSettings {...props} />;
export const UserIcon = ({ ...props }) => <User {...props} />;
